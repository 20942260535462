<template>
	<div class="record">
		<a-spin :spinning="loading">
			<a-form ref="form" :model="formState" name="form" @finish="onSearch">
				<a-row>
					<a-form-item class="ui-form__item" name="nickname" label="用户名称">
						<a-input v-model:value="formState.nickname" placeholder="请输入用户名称" />
					</a-form-item>
					<a-form-item class="ui-form__item" name="phone" label="用户手机">
						<a-input v-model:value="formState.phone" placeholder="请输入用户手机" />
					</a-form-item>
					
					<a-form-item label="类型" name="type" class="ui-form__item">
						<a-select v-model:value="formState.type" style="width: 180px;" allow-clear placeholder="请选择类型">
							<a-select-option :value="1">收入</a-select-option>
							<a-select-option :value="2">消费</a-select-option>
						</a-select>
					</a-form-item>
					
					<a-form-item label="变更类型" name="businessType" class="ui-form__item">
						<a-select v-model:value="formState.businessType" style="width: 180px;" allow-clear placeholder="请选择操作类型">
							<a-select-option :value="1">订单赠送</a-select-option>
							<a-select-option :value="2">订单消费</a-select-option>
							<a-select-option :value="3">订单退回</a-select-option>
							<a-select-option :value="4">订单售后</a-select-option>
							<a-select-option :value="5">签到</a-select-option>
							<a-select-option :value="6">积分过期</a-select-option>
							<a-select-option :value="7">会员权益领取</a-select-option>
							<a-select-option :value="8">订单售后扣取赠送积分</a-select-option>
							<a-select-option :value="9">管理员操作</a-select-option>
							<a-select-option :value="10">抽奖活动</a-select-option>
						</a-select>
					</a-form-item>
					
					<a-form-item class="ui-form__item" name="adminUserName" label="操作人">
						<a-input v-model:value="formState.adminUserName" placeholder="请输入操作人" />
					</a-form-item>
					
					<a-form-item class="ui-form__item" label="操作时间">
						<a-range-picker v-model:value="time"></a-range-picker>
					</a-form-item>
				</a-row>
				<a-row>
					<a-col :span="18">
					</a-col>
					<a-col :span="6" style="text-align: right;">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>

			<cTable ref="cTable" :isPage='true' :isRequest="true" :searchData="searchData" :requestFun="getUserPointRecord" :columns="columns"
				rowKey="id" size="middle" :scroll="{ x: 1000 }">
				<template #bodyCell="{ column, record }">
					<template v-if="column.key === 'userInfo'">
						<div>
							<div>用户昵称：{{ record.nickname || '-' }}</div>
							<div>用户手机号：{{ record.phone || '-' }}</div>
						</div>
					</template>
					
					<template v-if="column.key === 'type'">
						{{ record.type === 1 ? '收入' : '消费' }}
					</template>
					
					<template v-if="column.key === 'businessType'">
						<div>{{ transBusinessType(record.businessType) }}</div>
					</template>
					
					<template v-if="column.key === 'operateType'">
						{{ transOperateType(record.operateType) }}
					</template>
					
					<template v-if="column.key === 'createTime'">
						{{ transDateTime(record.createTime) }}
					</template>
				</template>
			</cTable>
		</a-spin>
	</div>
</template>

<script>
	import {
		Icon
	} from "@/components/icon/icon.js";
	import cTable from '@/components/cTable/index.vue';
	import { getUserPointRecord } from '@/service/modules/member.js';
	export default {
		name: "record",
		components: {
			Icon,
			cTable
		},
		data() {
			return {
				loading: false,
				showAll: false,
				formState: {

				},
				searchData: {},
				time: [],
				columns: [{
						title: "用户信息",
						key: 'userInfo'
					}, {
						title: '类型',
						key: 'type'
					},
					{
						title: "变更类型",
						key: "businessType",
					},
					{
						title: "变更前",
						dataIndex: "beforePoint",
						width: 150
					},
					{
						title: '变更',
						dataIndex: 'point',
						width: 150
					},
					{
						title: "变更后",
						dataIndex: "afterPoint",
						width: 150
					},
					{
						title: "操作人",
						dataIndex: "adminUserName",
						width: 100
					},
					{
						title: "操作时间",
						key: "createTime",
						width: 150
					}
				],
			};
		},
		mounted() {
			this.onSearch();
		},
		methods: {
			getUserPointRecord: getUserPointRecord,
			onSearch() {
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				if (this.time && this.time.length) {
					this.searchData.startTime = this.time[0].startOf('days').unix();
					this.searchData.endTime = this.time[1].endOf('days').unix();
				}
				this.getData();
			},
			reset() {
				this.time = [];
				this.$refs.form.resetFields();
				this.onSearch();
			},
			getData() {
				this.$refs.cTable.toQuery();
			},
			transBusinessType(type) {
				if (type === 1) {
					return '订单赠送';
				} else if (type === 2) {
					return '订单消费';
				} else if (type === 3) {
					return '订单退回';
				} else if (type === 4) {
					return '订单售后';
				} else if (type === 5) {
					return '签到'
				} else if (type === 6) {
					return '积分过期'
				} else if (type === 7) {
					return '会员权益领取'
				} else if (type === 8) {
					return '订单售后扣取赠送积分'
				} else if (type === 9) {
					return '管理员操作';
				} else if (type === 10) {
					return '抽奖活动';
				} else {
					return '-';
				}
			},
			transOperateType(type) {
				if (type === 'GRANT_MEMBER') {
					return '发放特色会员';
				} else if (type === 'DISABLED_MEMBER') {
					return '禁用/启用特色会员';
				} else if (type === 'RENEW_MEMBER') {
					return '续费特色会员';
				} else if (type === 'GRANT_POINT') {
					return '发放积分';
				} else if (type === 'SUB_POINT') {
					return '扣减积分';
				} else if (type === 'GRANT_UNION_CARD') {
					return '发放一卡通';
				} else if (type === 'ADD_UNION_CARD_BALANCE') {
					return '增加一卡通余额';
				} else if (type === 'SUB_UNION_CARD_BALANCE') {
					return '扣减一卡通余额';
				} else if (type === 'GRANT_COUPON') {
					return '发放优惠券';
				} else if (type === 'REMOVE_COUPON') {
					return '移除优惠券';
				} else if (type === 'DISABLED_COUPON') {
					return '禁用/启用优惠券';
				}
			}
		}
	};
</script>

<style scoped>
	.ui-form__item {
		margin-right: 20px;
	}
</style>